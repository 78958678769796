import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import styled from "styled-components"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"

const Root = {
  Wrapper: styled.div`
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    h1 {
      text-align: center;
      width: 100%;
    }

    @media (max-width: ${breakpoints.small}px) {
      min-height: 85vh;
    }
  `,
}

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Root.Wrapper>
      <h1>אופס.. כנראה שהלכתם לאיבוד.</h1>
      <h1>דף מבוקש לא נמצא.</h1>
    </Root.Wrapper>
  </Layout>
)

export default NotFoundPage
